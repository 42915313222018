

































































































import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import Spinners from "@/components/utilities/Spinners.vue";
import attendeeSearchVuexModule from "@/store/vuex-modules/searchAttendeeData";
import { AttendeeSearchResult } from "@/types/interfaces";

interface AttendeeListCompany {
    name: string;
    attendees: Array<AttendeeSearchResult>;
}

const attendeeSearchStore = getModule(attendeeSearchVuexModule);

@Component({
    components: {
        PageTitleHeader,
        Spinners
    }
})
export default class AttendeeList extends Vue {
    get pageOptions() {
        return this.$store.getters.getPageOptions("attendees");
    }

    get textHeader() {
        return this.pageOptions?.textHeader
            ? this.pageOptions.textHeader
            : "Full Attendee List";
    }

    get useAttendeeListByCompany() {
        return this.pageOptions?.fullAttendeeListByCompany;
    }

    get attendeeList() {
        if (attendeeSearchStore.attendeeList) {
            return attendeeSearchStore.attendeeList;
        } else {
            return [];
        }
    }

    get attendeeListByCompany() {
        const companies: Array<AttendeeListCompany> = [];

        this.attendeeList.forEach((attendee: AttendeeSearchResult) => {
            const att = { ...attendee };
            if (!att.companyName) {
                att.companyName = "No Associated Organization";
            }

            const company = companies.find(
                (item: AttendeeListCompany) => item.name === att.companyName
            );

            if (company) {
                company.attendees.push(attendee);
            } else {
                companies.push({
                    name: att.companyName,
                    attendees: [attendee]
                });
            }
        });

        companies.sort((a: AttendeeListCompany, b: AttendeeListCompany) => {
            const aN = a.name.toLowerCase();
            const bN = b.name.toLowerCase();
            return aN < bN ? -1 : aN > bN ? 1 : 0;
        });

        return companies;
    }

    get isLoaded() {
        return attendeeSearchStore.isAttendeeListLoaded;
    }

    /**
     * Lifecycle hooks
     */
    mounted() {
        if (!this.attendeeList.length) {
            attendeeSearchStore.getAttendeeList();
        }
    }

    /**
     * Methods
     */
    goToSearch() {
        this.$router.push("/attendees");
    }

    formatAttendeeListing(data: AttendeeSearchResult, omitCompanyName = false) {
        const fields =
            Array.isArray(
                this.$store.getters.getPageOptions("attendees")
                    .attendeeListFields
            ) &&
            this.$store.getters.getPageOptions("attendees").attendeeListFields
                .length
                ? this.$store.getters.getPageOptions("attendees")
                      .attendeeListFields
                : ["firstName", "lastName", "companyName"];

        const listingParts = [];
        const specialKeys = [
            "firstName",
            "lastName",
            "phone",
            "email",
            "prefix"
        ];

        if (omitCompanyName) {
            specialKeys.push("companyName");
        }

        const name = `<b>${data.firstName} ${data.lastName}</b>`;
        listingParts.push(name);

        const otherFields = fields.filter(
            (key: string) => !specialKeys.includes(key)
        );

        otherFields.forEach((field: keyof AttendeeSearchResult) => {
            if (field in data) {
                listingParts.push(data[field]);
            }
        });

        if (fields.includes("phone") && data.phone) {
            listingParts.push(`<b>Phone</b>: ${data.phone}`);
        }

        if (fields.includes("email") && data.email) {
            listingParts.push(
                `<b>Email</b>: <a href="${data.email}">${data.email}</a>`
            );
        }

        let string = listingParts.join(", ");

        if (fields.includes("prefix") && data.prefix) {
            string = `${data.prefix} ${string}`;
        }

        return string;
    }
}
